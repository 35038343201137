















































































































































































import { Component, Vue } from "vue-property-decorator"
import RestValuationRequest from "@/rest/RestValuationRequest"
import ValuationRequest from "@/models/ValuationRequest"
import FilterView from "@/views/case/component/FilterView.vue"

@Component({
  components: { FilterView },
})
export default class CaseListView extends Vue {
  private dtLoading = false
  private cases: ValuationRequest[] = []
  private headers = [
    { text: "", value: "status", sortable: false },
    // { text: "Address", value: "address", sortable: false },
    // { text: "Requested", value: "createdV", sortable: false },
    // { text: "Commenced", value: "commenced", sortable: false },
    // { text: "Deadline", value: "deadline", sortable: false },
    // { text: "Indicative Price", value: "price", sortable: false },
    // { text: "Valuer", value: "requestorName", sortable: false },
    // { text: "Customer", value: "customerName", sortable: false },
  ]
  private delayName = []
  private selectedDelay = ""
  private aa = 1
  private statusName = []
  private selectedStatus = ""
  private typeName = []
  private selectedType = ""
  mounted() {
    this.getCaseList()
  }

  getStatusDesc(status: string) {
    if (status == "STATUS_PENDING") return "Indication"
    if (status == "STATUS_INPROGRESS") return "Valuation in Progress"
    if (status == "STATUS_COMPLETE") return "Completed"
    if (status == "STATUS_CANCEL") return "KIV"
    if (status == "STATUS_FAIL") return "Unsuccessful"
    if (status == "STATUS_FINAL") return "Finalized"
    if (status == "STATUS_DISABL") return "Disable"
  }

  async getCaseList() {
    this.dtLoading = true
    try {
      const resp = await new RestValuationRequest().list(1)
      this.cases = resp.data.content
    } catch (e) {
      this.dtLoading = false
    } finally {
      this.dtLoading = false
    }
  }

  getColor(status: string) {
    if (status == "STATUS_PENDING") return "yellow darken-2"
    if (status == "STATUS_INPROGRESS") return "pink lighten-2"
    if (status == "STATUS_COMPLETE") return "indigo darken-3"
    if (status == "STATUS_CANCEL") return "red darken-2"
    if (status == "STATUS_FAIL") return "Unsuccessful"
    if (status == "STATUS_FINAL") return "purple darken-2"
    if (status == "STATUS_DISABL") return "Disable"
  }

  onAllCaseClick() {
    alert()
  }
}
