






















































































































































































































import { Component, Vue } from "vue-property-decorator"

@Component({
  components: {},
})
export default class FilterView extends Vue {
  private showAdvanceFilter = false
  private requestedDateMenu = false
  private requestedDate = []
  private deadlineMenu = false
  private deadlineDate = []
  private commencedDateMenu = false
  private commencedDate = []
  
  get requestedDateText() {
    return this.requestedDate.join(" ~ ")
  }
  get deadlineText() {
    return this.deadlineDate.join(" ~ ")
  }
  get commencedDateText() {
    return this.commencedDate.join(" ~ ")
  }
}
